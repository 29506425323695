.logo {
  padding: rem-calc(18 20 18 0);
  transition: all .2s ease-in-out;
  @include breakpoint(large) {
    padding: rem-calc(30 20 30 0);
  }
  a {
    display: inline-block;
    img {
      width: 80px;
      height: auto; //immer width und height in PX angeben (IE)
      transition: all .2s ease-in-out;
      @include breakpoint(medium) {
        width: 110px;
      }
      @include breakpoint(large) {
        //width: 180px;
      }
    }
  }
}

.opaque .logo {
  @include breakpoint(large) {
    padding: rem-calc(15 20 15 0);
  }
  img {
    @include breakpoint(large) {
      width: 80px;
    }
  }
}
