.full-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.no-margin-bottom {
  margin-bottom: 0.2em;
}

.bg-gold {
  background-color: $gold;
  color: $white;
  h3 {
    color: $white;
  }
}

.ausverkauft {
  @extend .soldout;
  display: inline-block;
  position: relative;
  transform: rotate(-12deg);
  padding: 6px 10px;
  right: auto;
  bottom: -5px;
}