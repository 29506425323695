.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $gold;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  &.opaque {
  }
}


.language {
  font-size: 1rem;
  @include breakpoint(large) {
    margin-top: rem-calc(22);
    text-align: right;
  }
}
.reservation {
  font-size: 1rem;
  @include breakpoint(large) {
    margin-top: rem-calc(23);
    text-align: right;
  }
}
