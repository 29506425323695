.reveal {
  top: 0 !important;
  .close-button {
    color: $white;
    font-size: 3.6em;
    &:hover {
      color: $gold;
    }
  }
}

#homeNewsModal {
  padding-top: rem-calc(50);
}