footer {
  background-color: $gold;
  padding: 5.5em 0 5em 0;
  color: $black;
  font-size: rem-calc(14);
  p {
    margin-bottom: 2em;
  }
  a {
    color: $black;
    text-decoration: none;
    &:hover {
      color: $white;
    }
  }
  h3 {
    font-size: 1.09375rem;
    font-family: $serif;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 0;
    color: $black;
  }
  .icon {
    transition: all .15s ease-in-out;
    &:hover {
      fill: $white;
    }
  }

}

.pre-footer {
  background-color: $white;
  padding: rem-calc(40);
  .logo-grid-pre-footer {
    text-align: center;
    img {
      width: rem-calc(140);
      display: inline-block;
      @include breakpoint(medium) {
        display: inline-block;
        height: rem-calc(40) !important;
        width: auto;
        margin: rem-calc(0 20 0 20) !important;
        width: auto !important;
      }
      @include breakpoint(large) {
        //margin: rem-calc(0 20 0 20) !important;
      }
    }
    a:first-child img {
      width: rem-calc(200);
      display: block;
      margin: 0 auto rem-calc(30) auto;
      @include breakpoint(medium) {
        display: inline-block;
      }
    }
    a:last-child img {
      display: block;
      margin: rem-calc(10) auto 0 auto !important;
      width: rem-calc(180);
      @include breakpoint(medium) {
        display: inline-block;
      }
      @include breakpoint(large) {
        margin-top:0 !important;
        margin-left: rem-calc(20) !important;
      }
    }
  }
}