#menu-desktop,
#menu-offcanvas {
  .menu {
    font-size: rem-calc(16);
    font-family: $serif;
    font-weight: bold;
    li {
      text-transform: uppercase;
      a {
        color: $black;
        letter-spacing: 0.1em;
        &::after {
          //remove default
          border: none;
          //add own "arrow"
          position: absolute;
          right: 0;
          top: 11px;
          width: 40px;
          height: 30px;
          z-index: 2;
          background-color: $primary-color;
          /*
          using mask to change background image svg color with background-color
          */
          //maybe need to add -webkit to every mask property to function in all browsers!?
          //-webkit-mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 17" style="enable-background:new 0 0 28 17; fill:white;" xml:space="preserve"><path d="M2.1,0c0.6,0,1.1,0.2,1.5,0.6L14,11.1L24.4,0.6C24.8,0.2,25.3,0,25.9,0s1.1,0.2,1.5,0.6S28,1.5,28,2.1 c0,0.6-0.2,1.1-0.6,1.5L14,17L0.6,3.6C0.2,3.2,0,2.7,0,2.1s0.2-1.1,0.6-1.5C1,0.2,1.5,0,2.1,0z"/></svg>');
          mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 17" style="enable-background:new 0 0 28 17; fill:white;" xml:space="preserve"><path d="M2.1,0c0.6,0,1.1,0.2,1.5,0.6L14,11.1L24.4,0.6C24.8,0.2,25.3,0,25.9,0s1.1,0.2,1.5,0.6S28,1.5,28,2.1 c0,0.6-0.2,1.1-0.6,1.5L14,17L0.6,3.6C0.2,3.2,0,2.7,0,2.1s0.2-1.1,0.6-1.5C1,0.2,1.5,0,2.1,0z"/></svg>');
          //-webkit-mask-size: 16px 10px;
          mask-size: 16px 10px;
          mask-repeat: no-repeat;
          mask-position: center center;
          transition: all 0.2s ease-in-out;
          &[aria-expanded="true"] {
            transform: rotate(180deg);
          }
        }
        &:hover {
          opacity: 1;
          color: $white;
        }
        &:focus {
          opacity: 1;
          color: $white;
        }
      }
      &.active {
        > a {
          background: transparent;
          color: white;
          &::after {
            background-color: transparent;
          }
        }
      }
    }
  }
}

#menu-desktop {
  margin-top: rem-calc(35);
  margin-left: rem-calc(40);
  @include breakpoint(xlarge) {
    margin-left: rem-calc(200);
  }
  transition: all .2s ease-in-out;
  .menu {
    li {
      a {
        @include breakpoint(1600) {
          padding-left: rem-calc(30);
          padding-right: rem-calc(30);
        }
      }
      &.last {
        flex: auto;
      }
      &.lang-choose {
        a {
          float: right;
          padding-left: rem-calc(10);
          padding-right: rem-calc(10);
        }
      }
      //&:last-child {
      //  border-left: 1px solid $black;
      //}
    }

  }

  .menu li a::after {
    right: -10px;
  }

}
.opaque {
  #menu-desktop {
    margin-top: rem-calc(11);
  }
}



#menu-offcanvas {
  margin-top: rem-calc(100);
  .menu {
    font-size: rem-calc(28);
    li {
      text-align: center;
      &.lang-choose {
        margin-top: rem-calc(30);
      }
    }
  }
}
