.details, .social {
  margin-top: 0.8em;
  margin-bottom: 1em;
}

.soldout {
  background-color: $red;
  color: $white;
  position: absolute;
  transform: rotate(-30deg);
  font-size: 0.8em;
  padding: 6px 100px;
  font-weight: bold;
  right: -68px;
  bottom: 27px;
  font-family: $serif;
  &.cancelled {
    font-size: 1.2em;
    bottom: 30%;
    padding: 6px 200px;
    right: -80px;
  }
}

.flyer {
  a {
    font-size: 0.8em;
    color: lighten($gold,5%);
    text-decoration: none;
    .svg-icon {
      font-size: 0.7em;
      margin-right: 0.4em;
      vertical-align: -0.3em;
      .icon {
        fill: lighten($gold,5%);
      }
    }
    &:hover {
      color: $white;
      .svg-icon .icon {
        fill: $white;
      }
    }
  }
}

.event-boxes {
  display: grid;
  grid-gap: 30px;
  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
  }
  .event-box-nolink {
    border: 1px solid $gold;
    border-width: var(--border-width);
    padding: 1em 1.2em;
    h3 {
      @include font-size(1.5,3);
      margin-bottom: 0.6em;
    }
  }
}

.responsive-embed iframe {
  border: 1px solid $gold;
}