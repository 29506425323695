.teaser-image {
  margin-bottom: .6em;
  @include breakpoint(medium) {
    margin-bottom: 1.6em;
  }
}

.teaser-content {
  padding-bottom: rem-calc(20);
  @include breakpoint(medium) {
    padding-bottom: 0;
    margin-bottom: 1.6em;
    &.left {
      padding-left: rem-calc(15);
    }
    &.right {
      padding-right: rem-calc(15);
    }
  }
  @include breakpoint(large) {
    &.left {
      padding-left: rem-calc(30);
    }
    &.right {
      padding-right: rem-calc(30);
    }
  }

  .pretitle {
    font-family: $serif;
    margin-bottom: 0.2em;
  }
  h2 {
    @include font-size(1.4,3);
    margin-bottom: 0.7em;
  }

}

//Teaser Swiss Night out
.teaser-sno {
  position: relative;
  height: rem-calc(350);
  margin-bottom: rem-calc(-100);
  margin-top: 2em;
  @include breakpoint(medium) {
    margin-bottom: rem-calc(-140);
  }
  @include breakpoint(large) {
    height: rem-calc(500);
    margin-bottom: rem-calc(-200);
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 80%;
    background:$red;
    background: linear-gradient(90deg, rgba(164,17,18,1) 40%, rgba(164,17,18,0) 100%);
    z-index: 1;
  }
  > a {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
  }
  img {
    width: 70%;
    height: 100%;
    margin-left: 30%;
    object-fit: cover;
  }
  .teaser-sno-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $gold-light;
    z-index: 2;
    text-align: center;
    width: 50%;
    max-width: rem-calc(500);
    left: 5%;
    @include breakpoint(large) {
      left: 10%;
    }
    h4 {
      font-family: $hand;
      @include font-size(3,6);
    }
    p {
      font-family: $serif;
      font-weight: bold;
      line-height: 1.1;
      margin-bottom: 0;
      @include font-size(1.2,3);
    }
  }
}