@font-face {
	font-family: 'PaintedParadise';
	src: url('../fonts/painted-paradise.eot');
	src: url('../fonts/painted-paradise.eot?#iefix') format('embedded-opentype'),
       url('../fonts/painted-paradise.woff2') format('woff2'),
	     url('../fonts/painted-paradise.woff') format('woff'),
	     url('../fonts/painted-paradise.ttf') format('truetype'),
	     url('../fonts/painted-paradise.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
  	font-display: swap;
}

@font-face{
    font-family:"Eureka";
    src:url("../fonts/1465208/6fbd019c-aa33-4203-88af-687a6ab2aadb.woff2") format("woff2"),
        url("../fonts/1465208/743ba8ff-c2e7-45f0-8bf5-4adabcdb8855.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Eureka";
    src:url("../fonts/1465220/89e37017-5097-4a45-a39b-c8caad90c64a.woff2") format("woff2"),
        url("../fonts/1465220/2a065355-4831-443b-a39e-7fcf42c6f400.woff") format("woff");
    font-weight: normal;
  	font-style: normal;
    font-display: swap;
}

//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

main {
  	@include font-size(1,1.5);
}

a:focus {
  	outline: none;
}

h1 {
	color: $gold;
	text-align: center;
	margin-top: 1.5em;
	margin-bottom: 1.2em;
	i {
		display: block;
		font-style: normal;
		font-family: $hand;
		font-weight: normal;
		@include font-size(2.2,5.625);
		transform: rotate(-3deg);
	}
	span {
		display: block;
		font-family: $serif;
		color: $white;
		font-weight: bold;
		line-height: 0.9;
		margin-top: 0.2em;
		@include font-size(2.1,5.5);
		//transform: rotate(3deg);
	}
}

h2 {
	font-family: $serif;
	font-weight: bold;
	color: $gold;
	margin-bottom: 1.3em;
	margin-top: 0.4em;
	@include font-size(1.5,3.75);
	i {
		font-style: normal;
	}
	+ h3 {
		font-family: $sans;
		@include font-size(1.1,2);
		margin-top: 0;
		margin-bottom: 1em;
		font-weight: normal;
	}
}

h3 {
	font-family: $serif;
	font-weight: bold;
	color: $gold;
	@include font-size(1.5,3.75);
	i {
		font-style: normal;
	}
}

h4 {
	@include font-size(1.5,2);
	font-weight: bold;
	i {
		font-style: normal;
	}
}

.callout h3 {
	@include font-size(1.2,3);
}

p,address {
	margin-bottom: 1em;
}

.richtext {
	margin-bottom: 2em;
}