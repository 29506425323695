.callout {
  padding: 1em;
  color: lighten($gold,5%);
}

.callout-grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 0px 20px;
  @include breakpoint(large) {
    gap: 0px 30px;
  }
}