img {
  image-rendering: -webkit-optimize-contrast;
}

figure {
  margin-top: 0.5em;
  margin-bottom: 2em;
}

#TA_certificateOfExcellence789 {
  > div {
    margin: 0;
  }
  //img {
  //  &::before {
  //    content: url(../images/TC_2022_L_KNOCKOUT_BG_RGB.png);
  //  }
  //}
}
