.link-box-wrapper {
  margin: 3em 0;
  display: block;
  //grid-template-columns: 1fr;
  //gap: 30px 30px;
  //@include breakpoint(620) {
  //  grid-template-columns: 1fr 1fr;
  //}
  //@include breakpoint(large) {
  //  grid-template-columns: 1fr 1fr 1fr;
  //}
  @include breakpoint(620) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .link-box-content {
    border: 5px solid $gold;
    padding: rem-calc(20);
    position: relative;
    text-align: center;
    overflow: hidden;
    transition: background-color .2s ease-in-out;
    margin: rem-calc(0 15 30 15);
    @include breakpoint(620) {
      flex: 0 0 calc(50% - 30px);
    }
    @include breakpoint(large) {
      flex: 0 0 calc(33.33% - 30px);
    }

    > a {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
    }
    p:nth-child(2) {
      font-family: $serif;
      font-weight: bold;
      margin-bottom: 0;
    }
    h3 {
      color: $gold;
      margin: .3em 0 .5em 0;
      line-height: 0.9;
      transition: color .2s ease-in-out;
      &:not(.eventtitle) {
        hyphens: auto;
      }
    }

    &:last-child {

    }
    &:hover {
      background-color: $gold;
      h3 {
        color: $white;
      }
    }
  }
}

