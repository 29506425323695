ul:not(.menu):not(.form):not(.orbit-container):not(.files) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}

.files {
  list-style-type: none;
  li {
    a {
      font-size: 0.8em;
      color: lighten($gold,5%);
      text-decoration: none;
      .svg-icon {
        font-size: 0.7em;
        margin-right: 0.4em;
        vertical-align: -0.3em;
        .icon {
          fill: lighten($gold,5%);
        }
      }
      &:hover {
        color: $white;
        .svg-icon .icon {
          fill: $white;
        }
      }
    }
  }
}