.orbit {
  margin-bottom: 2em;
}

.orbit-container {
  margin: 0;
}

.orbit-slide {
  background-color: $black;
  overflow: hidden;
  //.full-link {
  //  background-color: red;
  //  mix-blend-mode: color;
  //}

  .orbit-figure {
    height: 100%;
    width: 100%;
    background: rgba($gold,.6);
    mix-blend-mode: plus-lighter;
  }

  img {
    //opacity: .9;
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: top;
    position: relative;
    mix-blend-mode: luminosity;
    transition: all .2s ease-in-out;
    @include breakpoint(medium) {
      height: calc(100vh - 200px);
      max-height: rem-calc(800);
    }
    //&::before {
    //  content: " ";
    //  position: absolute;
    //  width: 100%;
    //  top: 0;
    //  bottom: 0;
    //  left: 0;
    //  background-color: red;
    //  mix-blend-mode: color;
    //}
  }
  &:hover {
    img {
      transform: scale(1.04);
    }
  }

  .status-banner {
    background-color: $red;
    position: absolute;
    color: $white;
    font-family: $serif;
    font-weight: bold;
    font-size: 1.6em;
    padding: 10px 200px;
    transform: rotate(-30deg);
    top: 60px;
    left: -90px;
    display: block;
  }

}

.orbit-caption {
  background-color: transparent;
  font-family: $hand;
  font-weight: normal;
  color: $gold;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.2em;
  transform: rotate(-3deg);
  @include font-size(3,8);
  text-shadow: 0 0 1px rgba($black,.5), 0 0 2px rgba($black,.3), 0 0 5px rgba($black,.3), 0 0 8px rgba($black,.4);
  span {
    display: block;
    font-family: $serif;
    color: $white;
    font-weight: bold;
    @include font-size(1.4,3.4);
    transform: rotate(3deg);
  }
}

.orbit-bullets {
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  bottom: 20px;
  right: 30px;
  z-index: 5;
}