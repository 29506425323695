.team-box {
  position: relative;
  .team-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: $gold;
    padding: rem-calc(30);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    .overlay-content {
      margin-top: 50%;
      text-align: center;
      color: $black;
      transform: translateY(-50%);
    }
  }
  &:hover {
    .team-overlay {
      opacity: 1;
    }
  }
}