body.folklore {
  background-color: $red;
  color: $beige;
  .h-fixed-top {
    background-color: $red;
  }


  #menu-desktop {
    ul {
      li {
        a {
          color: $beige;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }


  h1,h2 {
    color: $beige;
    span {
      color: $beige;
    }
  }

  footer {
    background-color: $black;
    color: $white;
    a {
      color: $white;
      &:hover {
        color: $beige;
      }
    }
    h3 {
      color: $white;
    }
    .svg-icon svg {
      fill: $white;
    }
    .button {
      border-color: $gold;
    }
  }

}