.hero {
  height: 50vh;
  position: relative;
  margin-bottom: 2em;

  @include breakpoint(medium) {
    height: 70vh;
  }
  @include breakpoint(620 down) {
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    filter: grayscale(100%) brightness(110%);
  }
  .caption {
    position: absolute;
    top: 50%;
    left: 5%;
    right: 5%;
    z-index: 2;
    transform: translateY(-30%);
    @include breakpoint(medium) {
      left: 20%;
      right: 20%;
    }
    @include breakpoint(620 down) {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      transform: translateY(0%);
    }
    h1 {
      @include font-size(3,8);
      text-shadow: 0 0 1px rgba($black,.5), 0 0 2px rgba($black,.3), 0 0 5px rgba($black,.3), 0 0 8px rgba($black,.4);
      span {
        @include font-size(1.4,3.4);
      }
      @include breakpoint(620 down) {
        margin-bottom: 1em;
        margin-top: 0.5em;
      }
    }
  }

  &::before, &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &:before {
    background: $gold;
    mix-blend-mode: color;
    opacity: 0.5;
  }
  &::after {
    background: $black;
    mix-blend-mode: multiply;
    opacity: 0.3;
  }
}