.button {
  //text-transform: uppercase;
  //letter-spacing: 0.1rem;
  font-size: 0.9em;
  text-decoration: none;
  &.hollow {
    color: $white;
    &:hover {
      background-color: rgba($gold, 1);
      color: $black;
    }
  }
  &.btn-centered {
    display: block;
    margin: 1em auto 3em auto;
    width: fit-content;
  }
}

.ticket-button {
  color: $black;
  padding: rem-calc(2 20 10 15);
  margin-top: rem-calc(20);
  .svg-icon {
    margin-right: rem-calc(20);
    .icon {
      transition: all .25s ease-out;
    }
  }
  &:hover {
    color: $white;
    .svg-icon .icon {
      fill: $white;
      transform: scale(1.1);
    }
  }
}

.btn-nl {
  font-size: 1em;
  background-color: $black;
  font-size: 1em;
  color: $gold;
}

.soldout-btn {
  background: $red;
  padding: 6px 15px;
  margin-top: 28px;
  display: inline-block;
  font-family: $serif;
  font-weight: bold;
  text-transform: uppercase;
}